body, html {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
    /* min-width: fit-content; */
    overflow-x: hidden;
    height: 100%;
  }

  div {
    box-sizing: border-box;
  }

  .sign {
    font-family: 'Bungee Inline', cursive;
    /* font-family: 'Fascinate', cursive; */
    /* font-family: 'Monoton', cursive; */
    /* font-family: 'Notable', sans-serif; */
    /* font-family: 'Press Start 2P', cursive; */

    color: #FF6363;
    outline: black;

    line-height: 100%;
  }

  .righteous {
    font-family: 'Righteous';
  }